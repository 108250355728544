import React from 'react';
import TemplatePage1 from '@components/TemplatePage/TemplatePage';
import styled from 'styled-components';
import { Link } from 'gatsby';
import adorno from '@images/adorno/adorno11.png';
import obj4 from '@images/objetivos/obj1/bulb-svgrepo-com.svg';
import obj3 from '@images/objetivos/obj1/gear-svgrepo-com.svg';
import obj2 from '@images/objetivos/obj1/notepad-svgrepo-com.svg';
import obj1 from '@images/objetivos/obj1/target-svgrepo-com.svg';

const IndexPage = () => {
  return (
    <TemplatePage1
      title={"Goal 1: Consolidate as the Latin American QUEST LAC Network"}
      showtitle={false}
      adorno={<Adorno/>}
      description={"Goal 1: Consolidate as the Latin American QUEST LAC Network"}
      content={(
        <Wrapper>
          <TextWrapper>
            <TitleGroup>
              <div>
                <h1>Objetivo</h1>
                <h2>1</h2>
              </div>
              <p>
                Consolidate as the Latin American QUEST LAC Network
              </p>
            </TitleGroup>
            <h2> By:</h2>

            <Card>
              <div>
                <h2><b>Core Centers Establishments</b></h2>
                <h3>
                  IECS - Argentina and UPCH - Perú
                </h3>
                <Button to={"/acerca/estructura/centroscore"} bg={"var(--color2)"} size={"14px"} >See more</Button>
              </div>
              <img src={obj1} alt={obj1} />
            </Card>


            <Card>
              <div>
                <h2><b>Strategic group of Regional Policies (GEPR)</b></h2>
                <h3>
                  Meet the members of our GEPR              </h3>
                <Button to={"/acerca/estructura/gepr"} bg={"var(--color2)"} size={"14px"} >See more</Button>
              </div>
              <img src={obj2} alt={obj2} />

            </Card>


            <Card>
              <div>
                <h2><b>Stablishing a Technical Assesment Comitee (CAT)</b></h2>
                <h3>
                  Meet the memebers of our CAT              </h3>
                <Button to={"/acerca/estructura/cat"} bg={"var(--color2)"} size={"14px"} >See more</Button>
              </div>
              <img src={obj3} alt={obj3} />
            </Card>


            <Card>
              <div>
                <h2><b>Identfy the inerested people</b></h2>
                <h3>
                  Interested on Public Health Research
                </h3>
              </div>
              <img src={obj4} alt={obj4} />
            </Card>

          </TextWrapper>
        </Wrapper>
      )}
    />
  )
};

export default IndexPage

const Adorno = styled.div`
position: absolute;
top: 0;
left:0;
height:110%;
@media only screen and (min-width:768px){
  width: 600px;
  background-image: url(${adorno});
background-size: cover;
background-repeat: no-repeat;
}
@media only screen and (min-width:1200px){
  width: 600px;
  background-image: url(${adorno});
background-size: cover;
background-repeat: no-repeat;
}
`;



const TitleGroup = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;


div{
  display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
  h1{
    color: var(--tema4);
  font-size: 18px;
  margin:0;
}

h2{
  font-size: 100px;
  line-height: 100px;
  color:white;
  font-weight: bold;
  text-shadow: 0px 0px 4px var(--tema4);
  margin:0;
}
}
p{
  font-size: 24px;
  font-weight: bold;
  color: var(--tema4);
}

`;

const TextWrapper = styled.div`
width: 100%;


h1{
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 24px;
}

h2{
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 5px;
}

h3{
  font-size: 14px;
}

text-align: right;

@media only screen and (min-width:640px){
  padding-left:20%;
}

@media only screen and (min-width:1024px){
  h1{
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 30px;
}

h2{
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 5px;
}

h3{
  font-size: 18px;
  color: transparent;
}
}


`;

const Card = styled.div`
display: flex;
flex-direction: row;
width: 100%;
align-items: center;
justify-content: center;


div{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  justify-content: end;
  margin-right:10px;
  margin-top:20px;
  margin-bottom:20px;
  border-right: 15px dotted lightgrey;
  padding-right: 50px;
  transform: translateX(53px);
}

:hover{
  color: var(--tema4);
  img{
    animation: animateHeart 1.2s infinite;
  }
  h3{
    color: var(--tema4);
  }
}

@keyframes animateHeart {
  0% {
    transform: scale(0.8);
  }
  5% {
    transform: scale(0.9);
  }
  10% {
    transform: scale(0.8);
  }
  15% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.8);
  }
}




img{
  width:75px;
  height:75px;
  z-index:99;
}

`;


const Wrapper = styled.div`
margin-top: 1rem;
display: flex;
flex-direction: column;
width:100%;
justify-content: flex-start;
align-items: center;
@media only screen and (min-width:768px){
align-items: flex-end;
}
`



const Button = styled(Link)`
float: ${props => props.float ? props.float : ""};
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
color: white;
padding: 10px 20px;
font-size: ${props => props.size ? props.size : "20px"};
background-color: ${props => props.bg ? props.bg : ""};
`